import config, { getCurrentTheme } from './config';
import actions from './actions';

const initState = {
  isActivated: false,
  changeThemes: getCurrentTheme(
    'changeThemes',
    config.changeThemes.defaultTheme || 'themedefault'
  ),
  topbarTheme: getCurrentTheme(
    'topbarTheme',
    config.topbarTheme.defaultTheme || 'themedefault'
  ),
  sidebarTheme: getCurrentTheme(
    'sidebarTheme',
    config.sidebarTheme.defaultTheme || 'themedefault'
  ),
  layoutTheme: getCurrentTheme(
    'layoutTheme',
    config.layoutTheme.defaultTheme || 'themedefault'
  ),
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.SWITCH_ACTIVATION:
      return {
        ...state,
        isActivated: !state.isActivated,
      };
    case actions.CHANGE_THEME:
      return {
        ...state,
        [action.attribute]: action.theme,
      };
    case actions.CHANGE_LOGO:
      return {
        ...state,
        siteLogoSrc: action.siteLogoSrc,
        siteName: action.siteName,
        siteFavIcon: action.siteFavIcon,
        sitePlaystoreLink: action.sitePlaystoreLink,
        siteApplestoreLink: action.siteApplestoreLink,
        StoreEnableSubscription: action.StoreEnableSubscription,
        EnableSubscription: action.EnableSubscription,
        EnableEbook: action.EnableEbook,
        EnableAudioTitle: action.EnableAudioTitle,
        EnableVideoTitle: action.EnableVideoTitle,
        EbooksDisplayText: action.EbooksDisplayText,
        AudioTitlesDisplayText: action.AudioTitlesDisplayText,
        VideoTitlesDisplayText: action.VideoTitlesDisplayText,
        siteId: action.siteId,
        siteInvalid : action.siteInvalid,
        MyCatalogDisplayText: action.MyCatalogDisplayText,
        ShowRelatedTitles: action.ShowRelatedTitles
      }
    case actions.CHANGE_STORE_PROPERTIES:
      return {
        ...state,
        siteLogoSrc: action.siteLogoSrc,
        siteName: action.siteName,
        siteFavIcon: action.siteFavIcon,
        sitePlaystoreLink: action.sitePlaystoreLink,
        siteApplestoreLink: action.siteApplestoreLink,
        StoreEnableSubscription: action.StoreEnableSubscription,
        EnableSubscription: action.EnableSubscription,
        EnableEbook: action.EnableEbook,
        EnableAudioTitle: action.EnableAudioTitle,
        EnableVideoTitle: action.EnableVideoTitle,
        EbooksDisplayText: action.EbooksDisplayText,
        AudioTitlesDisplayText: action.AudioTitlesDisplayText,
        VideoTitlesDisplayText: action.VideoTitlesDisplayText,
        siteId: action.siteId,
        about: action.about,
        terms: action.terms,
        termsUrl: action.termsUrl,
        privacy:action.privacy,
        privacyUrl:action.privacyUrl,
        SupportEmail: action.SupportEmail,
        siteInvalid : action.siteInvalid,
        ActionAfterPurchaseConfirmation: action.ActionAfterPurchaseConfirmation,
        ActionAfterPurchaseConfirmationLinkText: action.ActionAfterPurchaseConfirmationLinkText,
        CookieConsentUrl: action.CookieConsentUrl,
        EnableWebSubscriptionRedeem: action.EnableWebSubscriptionRedeem,
        EnableWebTitleRedeem: action.EnableWebTitleRedeem,
        SubscriptionMessageHtml: action.SubscriptionMessageHtml,
        ShowBuyTitle:action.ShowBuyTitle,
        ShowCookieConsent: action.ShowCookieConsent,
        EnableWebReader: action.EnableWebReader,
        Faq:action.Faq,
        EnableTax: action.EnableTax,
        ShowTitlePrice: action.ShowTitlePrice,
        CustomCode: action.CustomCode,
        ShowPapertrellBrand: action.ShowPapertrellBrand,
        titleText: action.titleText,
        MyLibrarySortOptions: action.MyLibrarySortOptions,
        AllTitlesSortOptions: action.AllTitlesSortOptions,
        ShowNewsletterControls: action.ShowNewsletterControls,
        NewsletterSignupText: action.NewsletterSignupText,
        PurchaseCountries: action.PurchaseCountries,
        CreditsPerRenewal: action.CreditsPerRenewal,
        EnableBuyWithCredits: action.EnableBuyWithCredits,
        EnableMblurb: action.EnableMblurb,
        SubscribeButtonText: action.SubscribeButtonText,
        SubscribeButtonSubText: action.SubscribeButtonSubText,
        HideFilters: action.HideFilters,
        EnableGiftingTitle: action.EnableGiftingTitle,
        MyCatalogDisplayText: action.MyCatalogDisplayText,
        ShowRelatedTitles: action.ShowRelatedTitles,
        ShelfColor: action.ShelfColor,
        ShopifyShop: action.ShopifyShop
      }
    case actions.CHANGE_SUBSCRIPTION:
      return {
        ...state,
        SubscriptionData : action.SubscriptionData,
        EnableSubscription: action.EnableSubscription,
      }
    case actions.CHANGE_FAVICON:
      return {
        ...state,
        siteFavIcon: action.siteFavIcon,
      }
    case actions.RESET_SUBSCRIPTION: 
      return {
        ...state,
        EnableSubscription: true,
        SubscriptionData: null
      }
    case actions.UPDATE_CREDIT_BALANCE:
      return {
        ...state,
        CreditBalance: action.CreditBalance,
        CreditCurrency: action.CreditCurrency
      }
    case actions.UPDATE_GIFT_INFO:
      return {
        ...state,
        RecipientName: action.RecipientName,
        RecipientEmail: action.RecipientEmail,
        RecipientMessage: action.RecipientMessage
      }
    default:
      return state;
  }
}
